<template>
  <v-card>
    <v-card-title>
      Bonificação
      <v-spacer />
      <v-btn
        dark
        color="primary"
        @click="calcBonus()"
      >
        <span
          v-if="!loadingSpinner"
        >
          <v-icon>
            {{ icons.mdiCalculatorVariantOutline }}
          </v-icon>
          <span>Calcular</span>
        </span>

        <v-progress-circular
          v-else
          indeterminate
          :size="25"
          color="gray"
        >
        </v-progress-circular>
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemsTable"
      loading-text="Carregando dados..."
      :loading="loading"
      fixed-header
      disable-sort
      group-by="region"
    >
      <!--eslint-disable-next-line vue/no-unused-vars-->
      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="item.users_id === loadingSpinnerModal ? false : true"
          medium
          class="me-2"
          @click="getItemsById(item.users_id)"
        >
          {{ icons.mdiEyeOutline }}
        </v-icon>
        <v-progress-circular
          v-if="item.users_id === loadingSpinnerModal ? true : false"
          indeterminate
          :size="25"
          color="gray"
        >
        </v-progress-circular>
      </template>
      <template
        v-slot:group.header="{items}"
      >
        <td
          class="header-color"
          colspan="10"
        >
          {{ items[0].region }}
        </td>
      </template>
      <template
        v-slot:foot="{items}"
      >
        <tfoot>
          <tr>
            <th class="text-center">
              <h3>
                Total
              </h3>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-center">
              {{ totalParcel02 !== 0 ? totalParcel02.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00" }}
            </th>
            <th class="text-center">
              {{ totalParcel04 !== 0 ? totalParcel04.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00" }}
            </th>
            <th class="text-center">
              {{ totalDelivery !== 0 ? totalDelivery.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00" }}
            </th>
            <th class="text-center">
              {{ total !== 0 ? total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00" }}
            </th>
            <th class="text-center"></th>
          </tr>
        </tfoot>
      </template>
    </v-data-table>

    <v-dialog
      v-model="openDetails"
      width="1000"
    >
      <v-card>
        <v-card-title>
          Funcionário: {{ vendorName }}
        </v-card-title>
        <v-data-table
          :headers="headers2"
          :items="dataModalById"
          disable-sort
        >
          <template
            v-slot:foot="{items}"
          >
            <tfoot>
              <tr>
                <th class="text-center">
                  <h3>
                    Total
                  </h3>
                </th>
                <th class="text-center">
                  {{ items.length }}
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-center">
                  {{ totalModal !== 0 ? totalModal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00" }}
                </th>
              </tr>
            </tfoot>
          </template>
        </v-data-table>
      </v-card>

      <!-- <bonification-details
        :data-modal-by-id="dataModalById"
        @close="openDetails = false"
      /> -->
    </v-dialog>
  </v-card>
</template>
<script>
// eslint-disable-next-line semi
import axiosIns from '@/plugins/axios';
import {
  mdiCalculatorVariantOutline, mdiClose, mdiEyeOutline, mdiFilterOutline, mdiMinusCircleOutline,
} from '@mdi/js'
// eslint-disable-next-line semi
// import BonificationDetails from './BonificationDetails.vue';

export default {
  components: {
    // BonificationDetails,
  },
  data() {
    return {
      totalParcel02: '',
      totalParcel04: '',
      totalDelivery: '',
      total: '',
      totalModal: '',
      filterBonus: {
        origin: '',
        vendorId: '',
        period: '',
      },
      listEmployees: [],
      openDetails: false,
      icons: {
        mdiEyeOutline,
        mdiMinusCircleOutline,
        mdiFilterOutline,
        mdiClose,
        mdiCalculatorVariantOutline,
      },
      headers: [
        { text: 'FUNCIONARIO', value: 'name', align: 'left' },
        { text: 'META', value: 'meta', align: 'center' },
        { text: 'VENDA', value: 'vendas', align: 'center' },
        { text: 'ÍNDICE', value: 'indice', align: 'center' },
        { text: '%', value: '', align: 'center' },
        { text: 'BONUS 2', value: 'parcel_02', align: 'center' },
        { text: 'BONUS 4', value: 'parcel_04', align: 'center' },
        { text: 'ENTREGA', value: 'delivery_value', align: 'center' },
        { text: 'TOTAL', value: 'total', align: 'center' },
        { text: 'AÇÕES', value: 'actions', align: 'center' },
      ],
      headers2: [
        { text: 'PROPOSTA', value: 'proposal_digit', align: 'center' },
        { text: 'CLIENTE', value: 'client_name', align: 'center' },
        { text: 'MODELO', value: 'vehicle_model', align: 'center' },
        { text: 'PARCELA 2', value: 'parcel_02', align: 'center' },
        { text: 'PARCELA 4', value: 'parcel_04', align: 'center' },
        { text: 'ENTREGA', value: 'delivery_value', align: 'center' },
        { text: 'VALOR', value: 'total', align: 'center' },
      ],

      itemsTable: [],
      regions: [
        { text: 'Imperatriz' },
        { text: 'Açailândia' },
        { text: 'Grajaú' },
        { text: 'Porto Franco' },
        { text: 'Amarante' },
        { text: 'Senador' },
        { text: 'Cidelândia' },
        { text: 'Itinga' },
        { text: 'Bom Jesus' },
        { text: 'Sitio Novo' },
        { text: 'Formosa' },
      ],
      loadingSpinner: false,
      heightDinamic: '800px',
      loading: true,
      dataModalById: [],
      loadingSpinnerModal: '',
      vendorName: '',

    }
  },

  computed: {
    optionsEmployee() {
      const listNameEmployees = []

      this.listEmployees.map(items => {
        items.map(item => {
          listNameEmployees.push(item.name)
        })
      })

      return listNameEmployees
    },

  },

  created() {
    this.getItemsListEmployees()
    this.getItemsTable()
  },

  methods: {
    clearFilter() {
      // Método para limpar o filtro e reiniciar o grid
    },
    async getItemsById(id) {
      this.loadingSpinnerModal = id
      await axiosIns.get(`api/v1/integration/cnh_bonus/detailed_bonus/${id}`)
        .then(res => {
          this.dataModalById = res.data.data[0].result.data
          this.totalModal = res.data.data[0].total
          // eslint-disable-next-line prefer-destructuring
          this.vendorName = res.data.data[0].result.data[0].name
        })
      this.loadingSpinnerModal = ''
      this.openDetails = true
    },

    async getItemsTable() {
      // endpoint de listagem dos itens da tabela
      this.loading = true
      this.heightDinamic = `${window.screen.height * 0.45}px`

      await axiosIns.get('api/v1/integration/cnh_bonus/bonus_summary')
        .then(res => {
          this.itemsTable = res.data.data[0].result.data
          this.totalParcel02 = res.data.data[0].totalParcel02
          this.totalParcel04 = res.data.data[0].totalParcel04
          this.totalDelivery = res.data.data[0].total_delivery
          this.total = res.data.data[0].total
        })
      this.loading = false
    },

    getFilterItems() {
      // end point para filtrar os items da tabela
    },

    async getItemsListEmployees() {
      await axiosIns.get('api/v1/records/user/index')
        .then(res => {
          this.listEmployees.push(res.data.data)
        })
    },

    async calcBonus() {
      this.loadingSpinner = true
      await axiosIns.get('api/v1/integration/cnh_bonus/bonus_calculation')
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Cálculo realizado!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Algo deu errado!',
            showConfirmButton: false,
            text: error,
            timer: 2000,
          })
        })
      this.loadingSpinner = false
    },

  },
}
</script>
<style>
</style>
