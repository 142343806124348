import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Bonificação "),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.calcBonus()}}},[(!_vm.loadingSpinner)?_c('span',[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiCalculatorVariantOutline)+" ")]),_c('span',[_vm._v("Calcular")])],1):_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading-text":"Carregando dados...","loading":_vm.loading,"fixed-header":"","disable-sort":"","group-by":"region"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.users_id === _vm.loadingSpinnerModal ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.getItemsById(item.users_id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]):_vm._e(),(item.users_id === _vm.loadingSpinnerModal ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()]}},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('td',{staticClass:"header-color",attrs:{"colspan":"10"}},[_vm._v(" "+_vm._s(items[0].region)+" ")])]}},{key:"foot",fn:function(ref){
var items = ref.items;
return [_c('tfoot',[_c('tr',[_c('th',{staticClass:"text-center"},[_c('h3',[_vm._v(" Total ")])]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.totalParcel02 !== 0 ? _vm.totalParcel02.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00")+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.totalParcel04 !== 0 ? _vm.totalParcel04.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00")+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.totalDelivery !== 0 ? _vm.totalDelivery.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00")+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.total !== 0 ? _vm.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00")+" ")]),_c('th',{staticClass:"text-center"})])])]}}],null,true)}),_c(VDialog,{attrs:{"width":"1000"},model:{value:(_vm.openDetails),callback:function ($$v) {_vm.openDetails=$$v},expression:"openDetails"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Funcionário: "+_vm._s(_vm.vendorName)+" ")]),_c(VDataTable,{attrs:{"headers":_vm.headers2,"items":_vm.dataModalById,"disable-sort":""},scopedSlots:_vm._u([{key:"foot",fn:function(ref){
var items = ref.items;
return [_c('tfoot',[_c('tr',[_c('th',{staticClass:"text-center"},[_c('h3',[_vm._v(" Total ")])]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(items.length)+" ")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.totalModal !== 0 ? _vm.totalModal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00")+" ")])])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }